/* eslint-disable */

var includeNav =
  window.location.search && window.location.search.indexOf('ref=cp') !== -1;
if (includeNav) {
  hubspot.require(['enviro'], function (Env) {
    var navEnv = Env.getShort('navigation');
    var subDomain = navEnv == 'local' ? 'local' : 'app';
    var domain = navEnv == 'prod' ? 'hubspot' : 'hubspotqa';
    document.write(
      '<script type="text/javascript" src="//' +
        subDomain +
        '.' +
        domain +
        '.com/nav-static/loader.js" ></script>'
    );
  });
}
